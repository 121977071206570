import { RefObject, useRef } from 'react'
import Image from 'next/image'
import { ComponentWhatYouGetCard } from 'types/generated/contentful-types'
import { compact } from '@/utils/Helpers'
import { Tab, TabPanel, Tabs, TabsAnimationFunction } from '../ui/Tabs'
import ForaSwiper from '../swiper/ForaSwiper'
import { WhatYouGetModuleTemplateProps } from './WhatYouGetModule'
import styles from '../swiper/components/what-you-get-module-tabs.module.css'
import { ButtonLink } from '../ui/ButtonLinks'

export const WhatYouGetModuleTabs = (
  props: WhatYouGetModuleTemplateProps
): JSX.Element => {
  return (
    <>
      {/* On mobile, it's a carousel */}
      <div className="lg:hidden">
        <CarouselTemplate {...props} />
      </div>
      {/* On desktop, it's tabs */}
      <div className="hidden lg:block">
        <TabsTemplate {...props} />
      </div>
    </>
  )
}

/* Carousel version of the component (currently being used for mobile) */
const CarouselTemplate = ({
  whatYouGetCardsCollection,
}: WhatYouGetModuleTemplateProps): JSX.Element => {
  return (
    <div
      className="grid place-items-center place-content-center"
      data-name="what-you-get-carousel"
    >
      <div className="w-screen pl-4">
        <ForaSwiper
          className={styles.foraSwiper}
          params={{
            pagination: true,
            keyboard: true,
            slidesPerView: 1.254,
            spaceBetween: 16,
            centeredSlides: false,
            breakpoints: {
              640: {
                slidesPerView: 2.254,
              },
            },
          }}
        >
          {compact(whatYouGetCardsCollection?.items).map((item, index) => (
            <Section key={`Slide_${index}`} {...item} />
          ))}
        </ForaSwiper>
      </div>
    </div>
  )
}

/* Tabs version of the component (currently being used for desktop) */
const TabsTemplate = ({
  whatYouGetCardsCollection,
}: WhatYouGetModuleTemplateProps): JSX.Element => {
  const tabs = compact(whatYouGetCardsCollection?.items)
  const lineRef = useRef<HTMLHRElement>(null)

  return (
    <Tabs>
      <div
        className="w-full px-12 pb-12 bg-darkSand"
        data-name="what-you-get-tabs"
      >
        <div className="pt-12 pb-22" data-name="tabs">
          <ul className="relative flex justify-between">
            {tabs.map((item, index) => (
              <li key={`Tab_${index}`} className="contents">
                <Tab
                  tabIndex={index}
                  className="relative block transition-colors duration-200 ease-in-out border-b border-transparent group fora-text-tab-1 hover:border-blackSand"
                  onSelectAnimation={(...args) =>
                    tabSelectAnimation(lineRef, ...args)
                  }
                >
                  {/* Pseudo-element expands button's clickable area  */}
                  <span className='relative block before:content-[""] before:absolute before:inset-y-[-3rem] before:inset-x-[-10px]'>
                    {item?.headline}
                  </span>
                </Tab>
              </li>
            ))}
            <hr
              ref={lineRef}
              aria-hidden="true"
              className="absolute bottom-0 left-0 w-0 border-t borderblackSand"
            />
          </ul>
        </div>
        <div className="grid" data-name="tab-panels">
          {tabs.map((item, index) => (
            <TabPanel
              key={`TabPanel_${index}`}
              tabIndex={index}
              style={{ gridArea: '1 / 1 / 2 / 2' }}
              inactiveClassName="opacity-0 pointer-events-none"
              onSelectAnimation={(...args) => tabPanelSelectAnimation(...args)}
              onDeselectAnimation={(...args) =>
                tabPanelDeselectAnimation(...args)
              }
            >
              <Section {...item} />
            </TabPanel>
          ))}
        </div>
      </div>
    </Tabs>
  )
}

/* The slide or panel */
const Section = ({
  headline,
  subHead,
  bulletOne,
  bulletTwo,
  bulletThree,
  bulletFour,
  iconOne,
  iconTwo,
  iconThree,
  iconFour,
  image,
  fallbackImage,
  buttonText,
  buttonUrl,
  cloudinaryImage,
  cloudinaryIconOne,
  cloudinaryIconTwo,
  cloudinaryIconThree,
  cloudinaryIconFour,
  cloudinaryFallbackImage,
}: ComponentWhatYouGetCard): JSX.Element => {
  const bulletPoints = [
    {
      bullet: bulletOne,
      cloudinaryIcon: cloudinaryIconOne?.[0],
      icon: iconOne,
    },
    {
      bullet: bulletTwo,
      cloudinaryIcon: cloudinaryIconTwo?.[0],
      icon: iconTwo,
    },
    {
      bullet: bulletThree,
      cloudinaryIcon: cloudinaryIconThree?.[0],
      icon: iconThree,
    },
    {
      bullet: bulletFour,
      cloudinaryIcon: cloudinaryIconFour?.[0],
      icon: iconFour,
    },
  ]
  return (
    <section
      className="grid h-full grid-cols-1 auto-rows-min bg-darkSand lg:grid-cols-2"
      data-name="section"
    >
      <div
        className="relative h-[67%] pb-[67%] lg:pb-[100%] lg:h-full lg:order-2"
        data-name="img-col"
      >
        <Media
          image={image}
          cloudinaryImage={cloudinaryImage?.[0]}
          fallbackImage={fallbackImage}
          cloudinaryFallbackImage={cloudinaryFallbackImage?.[0]}
          headline={headline}
        />
      </div>
      <div className="p-6 lg:p-0 lg:pr-22 lg:order-1" data-name="content-col">
        <h3 className="mb-6 fora-text-h3 md:mb-8">{headline}</h3>
        <p className="mb-4 md:mb-8 fora-text-body-2 text-darkStone lg:fora-text-body-1">
          {subHead}
        </p>
        <ul className="mt-4 border-b divide-y border-darkShell divide-darkShell lg:border-t">
          {bulletPoints.map(
            ({ bullet, icon, cloudinaryIcon }, index) =>
              bullet &&
              (cloudinaryIcon?.secure_url || icon?.url) && (
                <li
                  key={index}
                  className="flex items-center justify-start gap-3 py-2 min-h-[40px] lg:min-h-[64px] lg:py-4 lg:gap-4"
                >
                  <div className="flex-shrink-0 w-6 h-6 lg:w-8 lg:h-8">
                    <Icon image={icon} cloudinaryImage={cloudinaryIcon} />
                  </div>
                  <h4 className="fora-text-h8 text-darkStone">{bullet}</h4>
                </li>
              )
          )}
        </ul>
        {buttonText && buttonUrl && (
          <div className="mt-6">
            <ButtonLink
              href={buttonUrl}
              text={buttonText}
              target="_self"
              theme="transparent"
            />
          </div>
        )}
      </div>
    </section>
  )
}

type MediaProps = Pick<
  ComponentWhatYouGetCard,
  | 'image'
  | 'fallbackImage'
  | 'headline'
  | 'cloudinaryImage'
  | 'cloudinaryFallbackImage'
>

function Media({
  cloudinaryImage,
  image,
  fallbackImage,
  cloudinaryFallbackImage,
  headline,
}: MediaProps): JSX.Element {
  if (!cloudinaryImage && !image)
    image = cloudinaryFallbackImage || fallbackImage
  if (!cloudinaryImage?.secure_url && !image?.url) return <></>

  const isVideo =
    cloudinaryImage?.resource_type === 'video' ||
    image?.contentType?.includes('video')

  return isVideo ? (
    <video
      autoPlay={true}
      muted={true}
      loop={true}
      playsInline
      controls={false}
      className="absolute inset-0 object-cover object-center w-full h-full"
    >
      <source src={cloudinaryImage?.secure_url ?? image?.url} />
    </video>
  ) : (
    <Image
      src={cloudinaryImage?.secure_url ?? image?.url}
      alt={
        cloudinaryImage?.metadata?.alt ||
        image?.description ||
        `Fora - ${headline}`
      }
      fill
      style={{ objectFit: 'cover', objectPosition: 'center' }}
      sizes="90vw, (min-width: 640px) 50vw, (min-width: 1280px) 552px"
      priority
    />
  )
}

type IconProps = Pick<ComponentWhatYouGetCard, 'image' | 'cloudinaryImage'>

const Icon = ({ image, cloudinaryImage }: IconProps): JSX.Element => {
  if (!cloudinaryImage?.secure_url && !image?.url) return <></>
  return (
    <Image
      src={cloudinaryImage?.secure_url ?? image?.url ?? ''}
      alt={cloudinaryImage?.metadata?.alt ?? image?.description ?? ''}
      width={32}
      height={32}
      sizes="24px, (min-width: 1024px) 32px"
    />
  )
}

/* Tab Animation - Slide underline to selected tab */

type TabSelectAnimationFunction = (
  lineRef: RefObject<HTMLHRElement>,
  ...args: Parameters<TabsAnimationFunction>
) => ReturnType<TabsAnimationFunction>

const tabBaseDuration = 125
const increment = 75 // Increase duration for every tab to travel
const tabDuration = (activeTab: number, previousTab: number): number =>
  tabBaseDuration + Math.max(Math.abs(activeTab - previousTab), 1) * increment

const tabSelectAnimation: TabSelectAnimationFunction = (
  lineRef,
  element,
  activeTab,
  previousTab = 0
) => {
  if (!lineRef.current || !element) return

  // Move sliding line
  const elementWidth = element.getBoundingClientRect().width
  lineRef.current.animate(
    [
      {
        opacity: 1,
        width: `${lineRef.current.offsetWidth}px`,
        left: `${lineRef.current.offsetLeft}px`,
      },
      {
        opacity: 1,
        width: `${elementWidth}px`,
        left: `${element.offsetLeft}px`,
      },
    ],
    {
      duration: tabDuration(activeTab, previousTab),
      easing: 'cubic-bezier(0.4, 0, 0.2, 1)',
      fill: 'forwards',
    }
  )
}

/* Panel Animation - Slide panel on select */

const panelDuration = tabBaseDuration + increment

const tabPanelSelectAnimation: TabsAnimationFunction = (
  element,
  activeTab,
  previousTab = 0
) => {
  if (!element) return
  element.animate(
    [
      {
        opacity: 0,
        transform:
          activeTab > previousTab ? 'translateX(80px)' : 'translateX(-80px)',
      },
      {
        opacity: 1,
        transform: 'translateX(0)',
      },
    ],
    {
      duration: panelDuration,
      easing: 'cubic-bezier(0.4, 0, 0.2, 1)',
      fill: 'forwards',
    }
  )
}

const tabPanelDeselectAnimation: TabsAnimationFunction = (element) => {
  if (!element) return
  element.animate([{ opacity: 1 }, { opacity: 0 }], {
    duration: panelDuration,
    easing: 'cubic-bezier(0.4, 0, 0.2, 1)',
    fill: 'forwards',
  })
}
