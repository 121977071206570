import React, { useEffect, useRef, useState } from 'react'
import Image from 'next/image'
import { useSubNavBarContext } from 'context/SubNavBarContext'
import { WhatYouGetModuleTemplateProps } from './WhatYouGetModule'
import { ButtonLink } from '../ui/ButtonLinks'
import { useStickyHeaderContext } from 'context/StickyHeaderContext'

const getCurrentDimension = () => {
  if (typeof window !== 'undefined') {
    return {
      height: window.innerHeight,
    }
  }
}

export function WhatYouGetModuleParallax({
  whatYouGetCardsCollection,
}: WhatYouGetModuleTemplateProps): JSX.Element {
  const textBlocksRef = useRef<HTMLDivElement[]>([])
  const videoBlocksRef = useRef<HTMLVideoElement[] | HTMLDivElement[]>([])
  const containerRef = useRef<HTMLDivElement>(null)
  const stickyContainer = useRef<HTMLDivElement>(null)
  const [screenSize, setScreenSize] = useState(getCurrentDimension())
  const { isPresent: subNavIsPresent, subNavHeight } = useSubNavBarContext()
  const { isPresent: stickyHeaderIsPresent, stickyHeaderHeight } =
    useStickyHeaderContext()
  const stickyIsPresent = stickyHeaderIsPresent || subNavIsPresent
  const stickyOffset = subNavHeight + stickyHeaderHeight

  useEffect(() => {
    if (containerRef && containerRef.current) {
      if (stickyContainer && stickyContainer.current) {
        stickyContainer.current.style.height = `${
          containerRef.current.clientHeight - 488
        }px`

        // store the subnavbar height as a css variable for use in the sticky container height calculation
        if (stickyIsPresent) {
          stickyContainer.current.style.setProperty(
            '--sticky-offset',
            `${stickyOffset}px`
          )
        } else {
          stickyContainer.current.style.setProperty('--sticky-offset', `0px`)
        }
      }
    }
  }, [stickyIsPresent, stickyOffset])

  useEffect(() => {
    if (textBlocksRef.current[0])
      textBlocksRef.current[0].classList.remove('opacity-100')
    if (videoBlocksRef.current[0])
      videoBlocksRef.current[0].classList.remove('opacity-100')
    const updateDimension = () => {
      setScreenSize(getCurrentDimension())
    }
    if (window && typeof window !== 'undefined') {
      window.addEventListener('resize', updateDimension)
    }

    return () => {
      window.removeEventListener('resize', updateDimension)
    }
  }, [screenSize])

  useEffect(() => {
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        const { target, isIntersecting } = entry
        const index = textBlocksRef.current.indexOf(target as HTMLDivElement)
        const videoBlock = videoBlocksRef.current[index]
        if (!videoBlock || !target) return

        if (isIntersecting) {
          videoBlock.classList.add('opacity-100')
          videoBlock.classList.remove('opacity-0')
          target.classList.add('opacity-100')
          target.classList.remove('opacity-10')
          if (isIntersecting && index > 2) {
            if (!textBlocksRef.current[0] || !videoBlocksRef.current[0]) return
            textBlocksRef.current[0].classList.remove('opacity-100')
            textBlocksRef.current[0].classList.add('opacity-10')
            videoBlocksRef.current[0].classList.remove('opacity-100')
            videoBlocksRef.current[0].classList.add('opacity-0')
          }
        } else {
          target.classList.remove('opacity-100')
          target.classList.add('opacity-10')
          videoBlock.classList.remove('opacity-100')
          videoBlock.classList.add('opacity-0')
        }
      })
    }

    let options = {
      threshold: 0.5,
      rootMargin: '0px 0px -50% 0px',
    }

    if (screenSize && screenSize?.height < 1000) {
      options = {
        threshold: 0.6,
        rootMargin: '0px',
      }
    } else {
      options = {
        threshold: 0.5,
        rootMargin: '0px 0px -50% 0px',
      }
    }

    const observer = new IntersectionObserver(handleIntersection, options)

    if (textBlocksRef.current) {
      textBlocksRef.current.forEach((textBlock) => {
        observer.observe(textBlock)
      })
    }

    return () => {
      observer.disconnect()
    }
  }, [screenSize])
  return (
    <div className="flex md:gap-[112px] root-element">
      <div className="md:w-1/2" ref={containerRef}>
        {whatYouGetCardsCollection?.items.map((d, i) => (
          <>
            <div
              className="flex flex-col justify-center mb-4 md:hidden"
              key={`block-mobile-${i}`}
              data-name="mobile-block"
            >
              <div className="mb-8">
                {(d?.cloudinaryImage?.[0]?.resource_type === 'video' ||
                  d?.image?.contentType?.includes('video')) && (
                  <video autoPlay muted loop playsInline controls={false}>
                    <source
                      src={
                        d.cloudinaryImage?.[0]?.secure_url || d.image?.url || ''
                      }
                    />
                  </video>
                )}

                {(d?.cloudinaryImage?.[0]?.resource_type === 'image' ||
                  d?.image?.contentType?.includes('image')) && (
                  <Image
                    src={
                      d?.cloudinaryImage?.[0]?.secure_url ?? d?.image?.url ?? ''
                    }
                    data-src={
                      d?.cloudinaryImage?.[0]?.secure_url ?? d?.image?.url
                    }
                    alt={`Image ${
                      d?.cloudinaryImage?.[0]?.metadata.alt ||
                      d?.image?.description ||
                      ''
                    }`}
                    width={486}
                    height={486}
                  />
                )}
              </div>
              <h3 className="mb-4 fora-text-h3">{d?.headline}</h3>
              <div className="mb-8 global-richtext">
                <p>{d?.subHead}</p>
              </div>
              <ul>
                {(d?.cloudinaryIconOne?.[0]?.secure_url || d?.iconOne?.url) &&
                  d?.bulletOne && (
                    <li className="flex items-center mb-[34px]">
                      <div className="w-10 mr-3">
                        <Image
                          src={
                            d.cloudinaryIconOne?.[0]?.secure_url ??
                            d.iconOne?.url
                          }
                          data-src={
                            d.cloudinaryIconOne?.[0]?.secure_url ??
                            d.iconOne?.url
                          }
                          alt={`Icon ${
                            d.cloudinaryIconOne?.[0]?.metadata?.alt ||
                            d.iconOne?.description ||
                            ''
                          }`}
                          width={24}
                          height={24}
                          sizes="10vw"
                        />
                      </div>
                      <h3 className="fora-text-h6">{d.bulletOne}</h3>
                    </li>
                  )}
                {(d?.cloudinaryIconTwo?.[0]?.secure_url || d?.iconTwo?.url) &&
                  d.bulletTwo && (
                    <li className="flex items-center mb-[34px]">
                      <div className="w-10 mr-3">
                        <Image
                          src={
                            d.cloudinaryIconTwo?.[0]?.secure_url ??
                            d.iconTwo?.url
                          }
                          data-src={
                            d.cloudinaryIconTwo?.[0]?.secure_url ??
                            d.iconTwo?.url
                          }
                          alt={`Icon ${
                            d.cloudinaryIconTwo?.[0]?.metadata?.alt ||
                            d.iconTwo?.description ||
                            ''
                          }`}
                          width={24}
                          height={24}
                          sizes="10vw"
                        />
                      </div>
                      <h3 className="fora-text-h6">{d.bulletTwo}</h3>
                    </li>
                  )}
                {(d?.cloudinaryIconThree?.[0]?.secure_url ||
                  d?.iconThree?.url) &&
                  d.bulletThree && (
                    <li className="flex items-center mb-[34px]">
                      <div className="w-10 mr-3">
                        <Image
                          src={
                            d.cloudinaryIconThree?.[0]?.secure_url ??
                            d.iconThree?.url
                          }
                          data-src={
                            d.cloudinaryIconThree?.[0]?.secure_url ??
                            d.iconThree?.url
                          }
                          alt={`Icon ${
                            d.cloudinaryIconThree?.[0]?.metadata?.alt ||
                            d.iconThree?.description ||
                            ''
                          }`}
                          width={24}
                          height={24}
                          sizes="10vw"
                        />
                      </div>
                      <h3 className="fora-text-h6">{d.bulletThree}</h3>
                    </li>
                  )}
                {(d?.cloudinaryIconFour?.[0]?.secure_url || d?.iconFour?.url) &&
                  d.bulletFour && (
                    <li className="flex items-center mb-[34px]">
                      <div className="w-10 mr-3">
                        <Image
                          src={
                            d.cloudinaryIconFour?.[0]?.secure_url ??
                            d.iconFour?.url
                          }
                          data-src={
                            d.cloudinaryIconFour?.[0]?.secure_url ??
                            d.iconFour?.url
                          }
                          alt={`Icon ${
                            d.cloudinaryIconFour?.[0]?.metadata?.alt ||
                            d.iconFour?.description ||
                            ''
                          }`}
                          width={24}
                          height={24}
                          sizes="10vw"
                        />
                      </div>
                      <h3 className="fora-text-h6">{d.bulletFour}</h3>
                    </li>
                  )}
              </ul>
              {d?.buttonText && d?.buttonUrl && (
                <div className="mb-[34px]">
                  <ButtonLink
                    href={d.buttonUrl}
                    text={d.buttonText}
                    target="_self"
                    theme="transparent"
                  />
                </div>
              )}
            </div>
            <div
              className="hidden transition-opacity ease-linear duration-200 h-[488px] md:flex justify-center flex-col 2xl:mb-16"
              key={`block-${i}`}
              ref={(el) => {
                if (el) textBlocksRef.current[i] = el
              }}
              data-name="content-block"
            >
              <h3 className="mb-4 fora-text-h3">{d?.headline}</h3>
              <div className="mb-8 global-richtext">
                <p>{d?.subHead}</p>
              </div>
              <ul>
                {(d?.cloudinaryIconOne?.[0]?.secure_url || d?.iconOne?.url) &&
                  d?.bulletOne && (
                    <li className="flex perk-item mb-[34px]">
                      <div className="w-10 pb-3 m-auto lg:m-0 lg:pr-3 lg:pb-0">
                        <div className="relative w-7 h-7">
                          <Image
                            src={
                              d.cloudinaryIconOne?.[0]?.secure_url ??
                              d.iconOne?.url
                            }
                            data-src={
                              d.cloudinaryIconOne?.[0]?.secure_url ??
                              d.iconOne?.url
                            }
                            alt={`Icon ${
                              d?.cloudinaryIconOne?.[0]?.metadata.alt ||
                              d.iconOne?.description ||
                              ''
                            }`}
                            fill
                          />
                        </div>
                      </div>
                      <h3 className="fora-text-h6">{d.bulletOne}</h3>
                    </li>
                  )}
                {(d?.cloudinaryIconTwo?.[0]?.secure_url || d?.iconTwo?.url) &&
                  d.bulletTwo && (
                    <li className="flex perk-item mb-[34px]">
                      <div className="w-10 pb-3 m-auto lg:m-0 lg:pr-3 lg:pb-0">
                        <div className="relative w-7 h-7">
                          <Image
                            src={
                              d.cloudinaryIconTwo?.[0]?.secure_url ??
                              d.iconTwo?.url
                            }
                            data-src={
                              d.cloudinaryIconTwo?.[0]?.secure_url ??
                              d.iconTwo?.url
                            }
                            alt={`Icon ${
                              d?.cloudinaryIconTwo?.[0]?.metadata.alt ||
                              d.iconTwo?.description ||
                              ''
                            }`}
                            fill
                          />
                        </div>
                      </div>
                      <h3 className="fora-text-h6">{d.bulletTwo}</h3>
                    </li>
                  )}
                {(d?.cloudinaryIconThree?.[0]?.secure_url ||
                  d?.iconThree?.url) &&
                  d.bulletThree && (
                    <li className="flex perk-item mb-[34px]">
                      <div className="w-10 pb-3 m-auto lg:m-0 lg:pr-3 lg:pb-0">
                        <div className="relative w-7 h-7">
                          <Image
                            src={
                              d.cloudinaryIconThree?.[0]?.secure_url ??
                              d.iconThree?.url
                            }
                            data-src={
                              d.cloudinaryIconThree?.[0]?.secure_url ??
                              d.iconThree?.url
                            }
                            alt={`Icon ${
                              d?.cloudinaryIconThree?.[0]?.metadata.alt ||
                              d.iconThree?.description ||
                              ''
                            }`}
                            fill
                          />
                        </div>
                      </div>
                      <h3 className="fora-text-h6">{d.bulletThree}</h3>
                    </li>
                  )}
                {(d?.cloudinaryIconFour?.[0]?.secure_url || d?.iconFour?.url) &&
                  d.bulletFour && (
                    <li className="flex perk-item mb-[34px]">
                      <div className="w-10 pb-3 m-auto lg:m-0 lg:pr-3 lg:pb-0">
                        <div className="relative w-7 h-7">
                          <Image
                            src={
                              d.cloudinaryIconFour?.[0]?.secure_url ??
                              d.iconFour?.url
                            }
                            data-src={
                              d.cloudinaryIconFour?.[0]?.secure_url ??
                              d.iconFour?.url
                            }
                            alt={`Icon ${
                              d?.cloudinaryIconFour?.[0]?.metadata.alt ||
                              d.iconFour?.description ||
                              ''
                            }`}
                            fill
                          />
                        </div>
                      </div>
                      <h3 className="fora-text-h6">{d.bulletFour}</h3>
                    </li>
                  )}
              </ul>
              {d?.buttonText && d?.buttonUrl && (
                <div className="mb-[34px]">
                  <ButtonLink
                    href={d.buttonUrl}
                    text={d.buttonText}
                    target="_self"
                    theme="transparent"
                  />
                </div>
              )}
            </div>
          </>
        ))}
      </div>
      <div className="hidden w-1/2 md:block" ref={stickyContainer}>
        <div
          className="sticky"
          style={{
            top: stickyIsPresent ? 'calc(var(--sticky-offset) + 5rem)' : '5rem',
          }}
          data-name="video-block"
        >
          {whatYouGetCardsCollection?.items.map((v, idx) => {
            if (
              (v?.cloudinaryImage?.[0]?.secure_url || v?.image?.url) &&
              (v?.cloudinaryImage?.[0]?.resource_type === 'video' ||
                v?.image?.contentType?.includes('video'))
            ) {
              return (
                <video
                  autoPlay
                  muted
                  loop
                  playsInline
                  controls={false}
                  poster={
                    v.cloudinaryFallbackImage?.[0]?.secure_url ??
                    v.fallbackImage?.url ??
                    ''
                  }
                  className={`${
                    idx === 0 ? 'opacity-100' : 'opacity-0'
                  } m-auto max-h-[488px] absolute transition-opacity ease-linear duration-200 border-solid border-[1px] border-stone`}
                  key={`video-${idx}`}
                  ref={(el) => {
                    if (el) videoBlocksRef.current[idx] = el
                  }}
                  data-src={v.cloudinaryImage?.[0]?.secure_url ?? v.image?.url}
                  src={v.cloudinaryImage?.[0]?.secure_url ?? v.image?.url}
                />
              )
            } else if (
              v?.cloudinaryImage?.[0]?.resource_type === 'image' ||
              v?.image?.contentType?.includes('image')
            ) {
              return (
                <div
                  className="m-auto max-h-[488px] absolute transition-opacity ease-linear duration-500"
                  key={`image-${idx}`}
                  ref={(el) => {
                    if (el) videoBlocksRef.current[idx] = el
                  }}
                  data-src={v.cloudinaryImage?.[0]?.secure_url ?? v.image?.url}
                >
                  <Image
                    src={
                      v.cloudinaryImage?.[0]?.secure_url ?? (v.image?.url || '')
                    }
                    alt={`Image ${
                      v.cloudinaryImage?.[0]?.metadata?.alt ||
                      v.image?.description ||
                      ''
                    }`}
                    width={486}
                    height={486}
                  />
                </div>
              )
            }
          })}
        </div>
      </div>
    </div>
  )
}
